/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333333;
}

.App {
  width: 100%;
  overflow-x: hidden;
}

/* Navbar Styles */
.navbar {
  background-color: #0d47a1; /* Dark blue for a cleaner look */
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Evenly space links */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.navbar-brand {
  color: #ffffff;
  font-size: 1.8em;
  font-weight: bold;
  text-decoration: none;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between links */
}

.nav-link {
  color: #ffffff;
  font-size: 1.1em;
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth hover effect */
}

.nav-link:hover {
  color: #ffb74d; /* Orange highlight on hover */
}

/* Navigation Bar */
.navbar-nav .nav-item {
  margin-left: 15px;
}

.navbar-nav .nav-link {
  color: #ffffff !important;
  font-weight: 500;
}

.navbar-nav .nav-link:hover {
  color: #ffb74d !important;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, #64b5f6 0%, #2196f3 100%);
  color: #ffffff;
  padding: 100px 20px;
  text-align: center;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.3); /* Transparent overlay */
  padding: 40px;
  border-radius: 10px;
  max-width: 700px;
  margin: 0 auto;
}

.hero-section h1 {
  font-size: 2.8em;
  font-weight: bold;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.hero-section .cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ffb74d;
  color: #ffffff;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.hero-section .cta-button:hover {
  background-color: #ffa726;
}

/* Forms (Login and Signup) */
.container {
  max-width: 500px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.container h2 {
  font-size: 2em;
  font-weight: bold;
  color: #0d47a1;
  text-align: center;
  margin-bottom: 20px;
}

.container input {
  width: 100%;
  padding: 12px;
  margin: 10px 0 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure consistent box-sizing */
}

.container input:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.5);
}

.container button {
  width: 100%;
  padding: 12px;
  background-color: #4caf50;
  color: #ffffff;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.container button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.text-danger {
  color: #e53935; /* Bright red for error messages */
  font-size: 0.9em;
}

.text-success {
  color: #4caf50; /* Green for success messages */
  font-size: 0.9em;
  font-weight: bold;
}

/* Calculator Card */
.calculator-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.calculator-card h2 {
  color: #333333;
}

.calculator-card .form-group label {
  font-weight: bold;
  color: #555555;
}

.calculator-card .btn-primary {
  background-color: #007bff;
  border: none;
}

.calculator-card .btn-primary:hover {
  background-color: #0056b3;
}

/* Forms */
form input, form select {
  border-radius: 4px;
  border: 1px solid #cccccc;
}

form button {
  border-radius: 4px;
}

/* Demo Page */
.demo-page {
  padding: 60px 20px;
  background-color: #f9f9f9; /* Light background */
  text-align: center;
}

.demo-page h1 {
  font-size: 2.5em;
  font-weight: bold;
  color: #2196f3;
  margin-bottom: 20px;
}

.demo-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
  gap: 20px; /* Spacing between links */
  max-width: 800px;
  margin: 0 auto;
}

.demo-links a {
  padding: 15px;
  background-color: #0d47a1;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.demo-links a:hover {
  background-color: #2196f3; /* Lighter blue on hover */
  text-decoration: none;
}

/* Footer (Optional Additions) */
.footer {
  background-color: #0d47a1;
  color: #ffffff;
  text-align: center;
  padding: 20px;
  margin-top: 50px;
}

.footer p {
  margin: 0;
  font-size: 1em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
  }

  .container {
    padding: 20px;
  }

  .hero-section h1 {
    font-size: 2em;
  }

  .hero-section p {
    font-size: 1em;
  }

  .demo-links {
    grid-template-columns: 1fr; /* Stack links on small screens */
  }
}
