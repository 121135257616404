body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.navbar {
  margin-bottom: 20px;
}

.navbar .navbar-brand, .navbar .nav-link {
  color: white;
}

.navbar .nav-link:hover {
  color: #ffc107;
}

h1, h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.container {
  padding: 20px;
}

.section {
  padding: 60px 0;
}

.section-heading {
  margin-bottom: 40px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.hero-section {
  background-image: url('https://example.com/hero-image.jpg'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
  padding: 20px;
  border-radius: 10px;
}

.hero-section h1 {
  font-size: 4em;
  margin-bottom: 20px;
}

.hero-section .lead {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.under-construction {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9d835;
    color: #000;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
}


