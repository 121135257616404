/* frontend/src/components/AddFormula/AddFormula.css */
.add-formula-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-formula-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-formula-form div {
  display: flex;
  flex-direction: column;
}

.add-formula-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.add-formula-form input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.formula-hints {
  font-size: 0.9em;
  color: #555555;
}

.submit-button {
  padding: 10px;
  background-color: #28a745; /* Green */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #218838;
}